// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*.position.center {
    display: block;

    position: relative;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

*.position.center.animation.spin {
    animation-name: center-spin;
}

@keyframes center-spin { 
    from { 
        transform: translate(-50%, -50%) rotate(0deg);
    } to { 
        transform: translate(-50%, -50%) rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/classes/position.css"],"names":[],"mappings":"AAAA;IACI,cAAc;;IAEd,kBAAkB;;IAElB,QAAQ;IACR,SAAS;;IAET,gCAAgC;AACpC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI;QACI,6CAA6C;IACjD,EAAE;QACE,+CAA+C;IACnD;AACJ","sourcesContent":["*.position.center {\n    display: block;\n\n    position: relative;\n\n    top: 50%;\n    left: 50%;\n\n    transform: translate(-50%, -50%);\n}\n\n*.position.center.animation.spin {\n    animation-name: center-spin;\n}\n\n@keyframes center-spin { \n    from { \n        transform: translate(-50%, -50%) rotate(0deg);\n    } to { \n        transform: translate(-50%, -50%) rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
