// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;

    font-family: Arial;
    
    line-height: 150%;

    overflow: hidden;
}

body > div {
    display: flex;

    flex-direction: column;

    width: 100vw;
    height: 100dvh;
}`, "",{"version":3,"sources":["webpack://./src/styles/elements/body.css"],"names":[],"mappings":"AAAA;IACI,SAAS;;IAET,kBAAkB;;IAElB,iBAAiB;;IAEjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;;IAEb,sBAAsB;;IAEtB,YAAY;IACZ,cAAc;AAClB","sourcesContent":["body {\n    margin: 0;\n\n    font-family: Arial;\n    \n    line-height: 150%;\n\n    overflow: hidden;\n}\n\nbody > div {\n    display: flex;\n\n    flex-direction: column;\n\n    width: 100vw;\n    height: 100dvh;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
