// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*.animation.spin {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/classes/animation.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,0BAA0B;IAC1B,mCAAmC;IACnC,iCAAiC;AACrC;;AAEA;IACI;QACI,uBAAuB;IAC3B,EAAE;QACE,yBAAyB;IAC7B;AACJ","sourcesContent":["*.animation.spin {\n    animation-name: spin;\n    animation-duration: 2000ms;\n    animation-iteration-count: infinite;\n    animation-timing-function: linear;\n}\n\n@keyframes spin { \n    from { \n        transform: rotate(0deg); \n    } to { \n        transform: rotate(360deg); \n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
