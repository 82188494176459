// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.view.auth > div {
    background-color: rgb(245,245,245);
}

main.view.auth > div > div:first-child {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0.5em;
}

main.view.auth > div > div:first-child > div {
    background-color: white;

    box-shadow: 0.25em 0.25em 0.5em rgba(0,0,0,0.05);

    padding: 1em;

    max-width: 30em;

    border-radius: 1em;
}

main.view.auth > div > div:first-child > div > h5 {
    color: gray;
}

main.view.auth > div > div:first-child > div > h1 > span {
    white-space: nowrap;
}

main.view.auth > div > div:first-child > div > div {
    padding-top: 1em;
    padding-bottom: 1em;
}

main.view.auth > div > div:first-child > div > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    max-width: calc(100vw - 10em);
}

main.view.auth > div > div:first-child > div > div > button {
    margin-left: 0.25em;
    margin-right: 0.25em;

    width: 7.5em;
}

main.view.auth > div > div:first-child > div > div > input[type = 'email'] + button,
main.view.auth > div > div:first-child > div > div > input[type = 'text'] + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    margin-left: 0;
    margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/views/Auth.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;AACtC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;;IAEvB,gDAAgD;;IAEhD,YAAY;;IAEZ,eAAe;;IAEf,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;;IAE7B,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;;IAEpB,YAAY;AAChB;;AAEA;;IAEI,yBAAyB;IACzB,4BAA4B;;IAE5B,cAAc;IACd,eAAe;AACnB","sourcesContent":["main.view.auth > div {\n    background-color: rgb(245,245,245);\n}\n\nmain.view.auth > div > div:first-child {\n    padding-top: 0;\n    padding-left: 0;\n    padding-right: 0;\n    padding-bottom: 0.5em;\n}\n\nmain.view.auth > div > div:first-child > div {\n    background-color: white;\n\n    box-shadow: 0.25em 0.25em 0.5em rgba(0,0,0,0.05);\n\n    padding: 1em;\n\n    max-width: 30em;\n\n    border-radius: 1em;\n}\n\nmain.view.auth > div > div:first-child > div > h5 {\n    color: gray;\n}\n\nmain.view.auth > div > div:first-child > div > h1 > span {\n    white-space: nowrap;\n}\n\nmain.view.auth > div > div:first-child > div > div {\n    padding-top: 1em;\n    padding-bottom: 1em;\n}\n\nmain.view.auth > div > div:first-child > div > div > input {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n\n    max-width: calc(100vw - 10em);\n}\n\nmain.view.auth > div > div:first-child > div > div > button {\n    margin-left: 0.25em;\n    margin-right: 0.25em;\n\n    width: 7.5em;\n}\n\nmain.view.auth > div > div:first-child > div > div > input[type = 'email'] + button,\nmain.view.auth > div > div:first-child > div > div > input[type = 'text'] + button {\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n\n    margin-left: 0;\n    margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
