// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.widget.version_view_3d {
    position: relative;

    width: 100%;
    height: 100%;
    
    background-color: rgb(215,215,215);
}`, "",{"version":3,"sources":["webpack://./src/styles/widgets/VersionView3D.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;;IAEZ,kCAAkC;AACtC","sourcesContent":["div.widget.version_view_3d {\n    position: relative;\n\n    width: 100%;\n    height: 100%;\n    \n    background-color: rgb(215,215,215);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
