// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.legal > div > div {
    width: 60em;

    max-width: 100%;

    margin-left: auto;
    margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/views/Legal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;IAEX,eAAe;;IAEf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["main.legal > div > div {\n    width: 60em;\n\n    max-width: 100%;\n\n    margin-left: auto;\n    margin-right: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
