// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.view.product-milestone-issue div.header span.value.product_user_name {
    font-weight: bold;
}

main.view.product-milestone-issue div.header span.date {
    font-weight: bold;
}

main.view.product-milestone-issue td.assignees img {
    margin-left: 0.25em;
}
main.view.product-milestone-issue td.assignees img:first-child {
    margin-left: 0;
}

@media screen and (max-width: 45em){
    main.view.product-milestone-issue table > thead > tr > th:nth-child(4),
    main.view.product-milestone-issue table > thead > tr > th:nth-child(5),
    main.view.product-milestone-issue table > thead > tr > th:nth-child(6) {
        display: none;
    }
    main.view.product-milestone-issue table > tbody > tr > td:nth-child(4),
    main.view.product-milestone-issue table > tbody > tr > td:nth-child(5),
    main.view.product-milestone-issue table > tbody > tr > td:nth-child(6) {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/views/ProductMilestoneIssue.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;;;QAGI,aAAa;IACjB;IACA;;;QAGI,aAAa;IACjB;AACJ","sourcesContent":["main.view.product-milestone-issue div.header span.value.product_user_name {\n    font-weight: bold;\n}\n\nmain.view.product-milestone-issue div.header span.date {\n    font-weight: bold;\n}\n\nmain.view.product-milestone-issue td.assignees img {\n    margin-left: 0.25em;\n}\nmain.view.product-milestone-issue td.assignees img:first-child {\n    margin-left: 0;\n}\n\n@media screen and (max-width: 45em){\n    main.view.product-milestone-issue table > thead > tr > th:nth-child(4),\n    main.view.product-milestone-issue table > thead > tr > th:nth-child(5),\n    main.view.product-milestone-issue table > thead > tr > th:nth-child(6) {\n        display: none;\n    }\n    main.view.product-milestone-issue table > tbody > tr > td:nth-child(4),\n    main.view.product-milestone-issue table > tbody > tr > td:nth-child(5),\n    main.view.product-milestone-issue table > tbody > tr > td:nth-child(6) {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
