// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 40em) {
    main.view.user table > thead > tr > th:nth-child(3) {
        display: none;
    }
    main.view.user table > tbody > tr > td:nth-child(3) {
        display: none;
    }
    main.view.user table > thead > tr > th:nth-child(2) {
        width: 100%;
    }
    main.view.user table > tbody > tr > td:nth-child(2) {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/views/User.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;IACA;QACI,WAAW;IACf;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":["@media screen and (max-width: 40em) {\n    main.view.user table > thead > tr > th:nth-child(3) {\n        display: none;\n    }\n    main.view.user table > tbody > tr > td:nth-child(3) {\n        display: none;\n    }\n    main.view.user table > thead > tr > th:nth-child(2) {\n        width: 100%;\n    }\n    main.view.user table > tbody > tr > td:nth-child(2) {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
