// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.view.product-issue td.assignees img {
    margin-left: 0.25em;
}
main.view.product-issue td.assignees img:first-child {
    margin-left: 0;
}

@media screen and (max-width: 45em){
    main.view.product-issue table > thead > tr > th:nth-child(4),
    main.view.product-issue table > thead > tr > th:nth-child(5),
    main.view.product-issue table > thead > tr > th:nth-child(6),
    main.view.product-issue table > thead > tr > th:nth-child(7) {
        display: none;
    }
    main.view.product-issue table > tbody > tr > td:nth-child(4),
    main.view.product-issue table > tbody > tr > td:nth-child(5),
    main.view.product-issue table > tbody > tr > td:nth-child(6),
    main.view.product-issue table > tbody > tr > td:nth-child(7) {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/views/ProductIssue.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;;;;QAII,aAAa;IACjB;IACA;;;;QAII,aAAa;IACjB;AACJ","sourcesContent":["main.view.product-issue td.assignees img {\n    margin-left: 0.25em;\n}\nmain.view.product-issue td.assignees img:first-child {\n    margin-left: 0;\n}\n\n@media screen and (max-width: 45em){\n    main.view.product-issue table > thead > tr > th:nth-child(4),\n    main.view.product-issue table > thead > tr > th:nth-child(5),\n    main.view.product-issue table > thead > tr > th:nth-child(6),\n    main.view.product-issue table > thead > tr > th:nth-child(7) {\n        display: none;\n    }\n    main.view.product-issue table > tbody > tr > td:nth-child(4),\n    main.view.product-issue table > tbody > tr > td:nth-child(5),\n    main.view.product-issue table > tbody > tr > td:nth-child(6),\n    main.view.product-issue table > tbody > tr > td:nth-child(7) {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
