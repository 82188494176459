// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.value {
    display: inline-block;
}

span.value > span.loading {
    display: inline-block;

    width: 7em;
    height: 1.5em;

    border-radius: 0.75em;

    vertical-align: top;

    background-image: linear-gradient(rgba(0,0,0,0.05),rgba(0,0,0,0.10));
}`, "",{"version":3,"sources":["webpack://./src/styles/classes/value.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;;IAErB,UAAU;IACV,aAAa;;IAEb,qBAAqB;;IAErB,mBAAmB;;IAEnB,oEAAoE;AACxE","sourcesContent":["span.value {\n    display: inline-block;\n}\n\nspan.value > span.loading {\n    display: inline-block;\n\n    width: 7em;\n    height: 1.5em;\n\n    border-radius: 0.75em;\n\n    vertical-align: top;\n\n    background-image: linear-gradient(rgba(0,0,0,0.05),rgba(0,0,0,0.10));\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
