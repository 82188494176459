// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* View footer */

footer.page {
    flex: 0;

    display: none;

    background-color: rgb(245,245,245);

    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(215,215,215);
}
footer.page > div {
    white-space: nowrap;

    overflow-y: hidden;
    overflow-x: auto;
}
footer.page > div > span > a {
    display: inline-block;
    
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.75em;

    color: black;
}
footer.page > div > span > a.active {
    font-weight: bold;

    border-top-width: 4px;
    border-top-style: solid;
    border-top-color: orange;

    padding-top: calc(0.75em - 1px);

    margin-top: -1px;
}

@media screen and (max-width: 70em) {
    footer.page {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/elements/footer.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,OAAO;;IAEP,aAAa;;IAEb,kCAAkC;;IAElC,qBAAqB;IACrB,uBAAuB;IACvB,kCAAkC;AACtC;AACA;IACI,mBAAmB;;IAEnB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,qBAAqB;;IAErB,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB;;IAEtB,YAAY;AAChB;AACA;IACI,iBAAiB;;IAEjB,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;;IAExB,+BAA+B;;IAE/B,gBAAgB;AACpB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":["/* View footer */\n\nfooter.page {\n    flex: 0;\n\n    display: none;\n\n    background-color: rgb(245,245,245);\n\n    border-top-width: 1px;\n    border-top-style: solid;\n    border-top-color: rgb(215,215,215);\n}\nfooter.page > div {\n    white-space: nowrap;\n\n    overflow-y: hidden;\n    overflow-x: auto;\n}\nfooter.page > div > span > a {\n    display: inline-block;\n    \n    padding-left: 1em;\n    padding-right: 1em;\n    padding-bottom: 0.75em;\n\n    color: black;\n}\nfooter.page > div > span > a.active {\n    font-weight: bold;\n\n    border-top-width: 4px;\n    border-top-style: solid;\n    border-top-color: orange;\n\n    padding-top: calc(0.75em - 1px);\n\n    margin-top: -1px;\n}\n\n@media screen and (max-width: 70em) {\n    footer.page {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
