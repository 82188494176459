// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
    font-size: 200%;
    
    line-height: 150%;
    
    margin: 0;
    
    padding-top: calc(0.75rem - 0.25em);
    padding-bottom: calc(0.75rem - 0.25em);
}

h2 {
    font-size: 175%;
    
    line-height: 150%;

    margin: 0;
    
    padding-top: calc(0.75rem - 0.25em);
    padding-bottom: calc(0.75rem - 0.25em);
}

h3 {
    font-size: 150%;
    
    line-height: 150%;
    
    margin: 0;
    
    padding-top: calc(0.75rem - 0.25em);
    padding-bottom: calc(0.75rem - 0.25em);
}

h4 {
    font-size: 125%;
    
    line-height: 150%;
    
    margin: 0;
    
    padding-top: calc(0.75rem - 0.25em);
    padding-bottom: calc(0.75rem - 0.25em);
}

h5 {
    font-size: 100%;
    
    line-height: 150%;
    
    margin: 0;
    
    padding-top: calc(0.75rem - 0.25em);
    padding-bottom: calc(0.75rem - 0.25em);
}

h1 > a::after {
    content: " / ";
}
h1 > a:last-child::after {
    content: "";
}

h2 > a::after {
    content: " | ";
}
h2 > a:last-child::after {
    content: "";
}

h3 > strong {
    color: orange;
}`, "",{"version":3,"sources":["webpack://./src/styles/elements/heading.css"],"names":[],"mappings":"AAAA;IACI,eAAe;;IAEf,iBAAiB;;IAEjB,SAAS;;IAET,mCAAmC;IACnC,sCAAsC;AAC1C;;AAEA;IACI,eAAe;;IAEf,iBAAiB;;IAEjB,SAAS;;IAET,mCAAmC;IACnC,sCAAsC;AAC1C;;AAEA;IACI,eAAe;;IAEf,iBAAiB;;IAEjB,SAAS;;IAET,mCAAmC;IACnC,sCAAsC;AAC1C;;AAEA;IACI,eAAe;;IAEf,iBAAiB;;IAEjB,SAAS;;IAET,mCAAmC;IACnC,sCAAsC;AAC1C;;AAEA;IACI,eAAe;;IAEf,iBAAiB;;IAEjB,SAAS;;IAET,mCAAmC;IACnC,sCAAsC;AAC1C;;AAEA;IACI,cAAc;AAClB;AACA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;AACA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":["h1 {\n    font-size: 200%;\n    \n    line-height: 150%;\n    \n    margin: 0;\n    \n    padding-top: calc(0.75rem - 0.25em);\n    padding-bottom: calc(0.75rem - 0.25em);\n}\n\nh2 {\n    font-size: 175%;\n    \n    line-height: 150%;\n\n    margin: 0;\n    \n    padding-top: calc(0.75rem - 0.25em);\n    padding-bottom: calc(0.75rem - 0.25em);\n}\n\nh3 {\n    font-size: 150%;\n    \n    line-height: 150%;\n    \n    margin: 0;\n    \n    padding-top: calc(0.75rem - 0.25em);\n    padding-bottom: calc(0.75rem - 0.25em);\n}\n\nh4 {\n    font-size: 125%;\n    \n    line-height: 150%;\n    \n    margin: 0;\n    \n    padding-top: calc(0.75rem - 0.25em);\n    padding-bottom: calc(0.75rem - 0.25em);\n}\n\nh5 {\n    font-size: 100%;\n    \n    line-height: 150%;\n    \n    margin: 0;\n    \n    padding-top: calc(0.75rem - 0.25em);\n    padding-bottom: calc(0.75rem - 0.25em);\n}\n\nh1 > a::after {\n    content: \" / \";\n}\nh1 > a:last-child::after {\n    content: \"\";\n}\n\nh2 > a::after {\n    content: \" | \";\n}\nh2 > a:last-child::after {\n    content: \"\";\n}\n\nh3 > strong {\n    color: orange;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
