// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.widget.product_view_3d {
    position: relative;

    width: 100%;
    height: 100%;
    
    background-color: rgb(215,215,215);
}

div.widget.product_view_3d > select {
    position: absolute;

    top: 1em;
    left: 1em;
    
    max-width: calc(100% - 22em);

    z-index: 1;
}

@media screen and (max-width: 100em) {
    div.widget.product_view_3d > select {
        max-width: calc(100% - 5em);
    }
}

@media screen and (max-width: 70em) {
    div.widget.product_view_3d > select {
        max-width: calc(100% - 22em);
    }
}

@media screen and (max-width: 50em) {
    div.widget.product_view_3d > select {
        max-width: calc(100% - 5em);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/widgets/ProductView3D.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,WAAW;IACX,YAAY;;IAEZ,kCAAkC;AACtC;;AAEA;IACI,kBAAkB;;IAElB,QAAQ;IACR,SAAS;;IAET,4BAA4B;;IAE5B,UAAU;AACd;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,4BAA4B;IAChC;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ","sourcesContent":["div.widget.product_view_3d {\n    position: relative;\n\n    width: 100%;\n    height: 100%;\n    \n    background-color: rgb(215,215,215);\n}\n\ndiv.widget.product_view_3d > select {\n    position: absolute;\n\n    top: 1em;\n    left: 1em;\n    \n    max-width: calc(100% - 22em);\n\n    z-index: 1;\n}\n\n@media screen and (max-width: 100em) {\n    div.widget.product_view_3d > select {\n        max-width: calc(100% - 5em);\n    }\n}\n\n@media screen and (max-width: 70em) {\n    div.widget.product_view_3d > select {\n        max-width: calc(100% - 22em);\n    }\n}\n\n@media screen and (max-width: 50em) {\n    div.widget.product_view_3d > select {\n        max-width: calc(100% - 5em);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
