// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
    text-decoration: none;
    
    color: var(--blue);
    
    cursor: pointer;
}

a > img.icon:first-child {
    margin-right: 0.25em;
}
a > img.icon:last-child {
    margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/elements/anchor.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;;IAErB,kBAAkB;;IAElB,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB","sourcesContent":["a {\n    text-decoration: none;\n    \n    color: var(--blue);\n    \n    cursor: pointer;\n}\n\na > img.icon:first-child {\n    margin-right: 0.25em;\n}\na > img.icon:last-child {\n    margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
