// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.widget.model_view_3d {
    width: 100%;
    height: 100%;
    
    background-color: rgb(215,215,215);
}
div.widget.model_view_3d > canvas {
    background-image: linear-gradient(rgba(0,0,0,00), rgba(0,0,0,0.05), rgba(0,0,0,0.15), rgba(0,0,0,0.4));
}`, "",{"version":3,"sources":["webpack://./src/styles/widgets/ModelView3D.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,kCAAkC;AACtC;AACA;IACI,sGAAsG;AAC1G","sourcesContent":["div.widget.model_view_3d {\n    width: 100%;\n    height: 100%;\n    \n    background-color: rgb(215,215,215);\n}\ndiv.widget.model_view_3d > canvas {\n    background-image: linear-gradient(rgba(0,0,0,00), rgba(0,0,0,0.05), rgba(0,0,0,0.15), rgba(0,0,0,0.4));\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
