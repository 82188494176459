// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*.icon {
    display: inline-block;

    vertical-align: top;

    width: 1.5em;
    height: 1.5em;
}

*.icon.top {
    vertical-align: top;
}
*.icon.middle {
    vertical-align: middle;
}
*.icon.bottom {
    vertical-align: bottom;
}

*.icon.small {
    width: 1.5em;
    height: 1.5em;
}
*.icon.medium {
    width: 3em;
    height: 3em;
}
*.icon.large {
    width: 4.5em;
    height: 4.5em;
}

*.icon.small.pad {
    width: 1em;
    height: 1em;

    padding: 0.25em;
}
*.icon.medium.pad {
    width: 1em;
    height: 1em;

    padding: 1em;
}
*.icon.large.pad {
    width: 1em;
    height: 1em;

    padding: 1.75em;
}

*.icon.round {
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/styles/classes/icon.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;;IAErB,mBAAmB;;IAEnB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;IACI,UAAU;IACV,WAAW;AACf;AACA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,WAAW;;IAEX,eAAe;AACnB;AACA;IACI,UAAU;IACV,WAAW;;IAEX,YAAY;AAChB;AACA;IACI,UAAU;IACV,WAAW;;IAEX,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["*.icon {\n    display: inline-block;\n\n    vertical-align: top;\n\n    width: 1.5em;\n    height: 1.5em;\n}\n\n*.icon.top {\n    vertical-align: top;\n}\n*.icon.middle {\n    vertical-align: middle;\n}\n*.icon.bottom {\n    vertical-align: bottom;\n}\n\n*.icon.small {\n    width: 1.5em;\n    height: 1.5em;\n}\n*.icon.medium {\n    width: 3em;\n    height: 3em;\n}\n*.icon.large {\n    width: 4.5em;\n    height: 4.5em;\n}\n\n*.icon.small.pad {\n    width: 1em;\n    height: 1em;\n\n    padding: 0.25em;\n}\n*.icon.medium.pad {\n    width: 1em;\n    height: 1em;\n\n    padding: 1em;\n}\n*.icon.large.pad {\n    width: 1em;\n    height: 1em;\n\n    padding: 1.75em;\n}\n\n*.icon.round {\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
