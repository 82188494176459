// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 65em) {
    main.view.product table > tbody > tr > td > div.model {
        width: 5em;
        height: 5em;
    }
}

@media screen and (max-width: 55em) {
    main.view.product table > * > tr > *:nth-child(3),
    main.view.product table > * > tr > *:nth-child(4),
    main.view.product table > * > tr > *:nth-child(5) {
        display: none;
    }
}

@media screen and (max-width: 45em) {
    main.view.product table > * > tr > *:nth-child(6) {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/views/Product.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;QACV,WAAW;IACf;AACJ;;AAEA;IACI;;;QAGI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media screen and (max-width: 65em) {\n    main.view.product table > tbody > tr > td > div.model {\n        width: 5em;\n        height: 5em;\n    }\n}\n\n@media screen and (max-width: 55em) {\n    main.view.product table > * > tr > *:nth-child(3),\n    main.view.product table > * > tr > *:nth-child(4),\n    main.view.product table > * > tr > *:nth-child(5) {\n        display: none;\n    }\n}\n\n@media screen and (max-width: 45em) {\n    main.view.product table > * > tr > *:nth-child(6) {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
