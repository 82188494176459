// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 50em) {
    main.view.product-milestone table > thead > tr > th:nth-child(3),
    main.view.product-milestone table > thead > tr > th:nth-child(4) {
        display: none;
    }
    main.view.product-milestone table > tbody > tr > td:nth-child(3),
    main.view.product-milestone table > tbody > tr > td:nth-child(4){
        display: none;
    }
}
@media screen and (max-width: 40em) {
    main.view.product-milestone table > thead > tr > th:nth-child(5),
    main.view.product-milestone table > thead > tr > th:nth-child(6) {
        display: none;
    }
    main.view.product-milestone table > tbody > tr > td:nth-child(5),
    main.view.product-milestone table > tbody > tr > td:nth-child(6) {
        display: none;
    }
}
@media screen and (max-width: 30em) {
    main.view.product-milestone table > thead > tr > th:nth-child(7) {
        display: none;
    }
    main.view.product-milestone table > tbody > tr > td:nth-child(7) {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/views/ProductMilestone.css"],"names":[],"mappings":"AAAA;IACI;;QAEI,aAAa;IACjB;IACA;;QAEI,aAAa;IACjB;AACJ;AACA;IACI;;QAEI,aAAa;IACjB;IACA;;QAEI,aAAa;IACjB;AACJ;AACA;IACI;QACI,aAAa;IACjB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":["@media screen and (max-width: 50em) {\n    main.view.product-milestone table > thead > tr > th:nth-child(3),\n    main.view.product-milestone table > thead > tr > th:nth-child(4) {\n        display: none;\n    }\n    main.view.product-milestone table > tbody > tr > td:nth-child(3),\n    main.view.product-milestone table > tbody > tr > td:nth-child(4){\n        display: none;\n    }\n}\n@media screen and (max-width: 40em) {\n    main.view.product-milestone table > thead > tr > th:nth-child(5),\n    main.view.product-milestone table > thead > tr > th:nth-child(6) {\n        display: none;\n    }\n    main.view.product-milestone table > tbody > tr > td:nth-child(5),\n    main.view.product-milestone table > tbody > tr > td:nth-child(6) {\n        display: none;\n    }\n}\n@media screen and (max-width: 30em) {\n    main.view.product-milestone table > thead > tr > th:nth-child(7) {\n        display: none;\n    }\n    main.view.product-milestone table > tbody > tr > td:nth-child(7) {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
