// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.view.product-issue-comment div.header span.number {
    opacity: 0.5;
}
main.view.product-issue-comment div.header span.number::before {
    content: '#';
}
main.view.product-issue-comment div.header span.number::after {
    content: ' - ';
}

main.view.product-issue-comment div.header span.value.product_user_name {
    font-weight: bold;
}

main.view.product-issue-comment div.header span.date {
    font-weight: bold;
}

main.view.product-issue-comment div.header span.assignees > * {
    margin-left: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/styles/views/ProductIssueComment.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["main.view.product-issue-comment div.header span.number {\n    opacity: 0.5;\n}\nmain.view.product-issue-comment div.header span.number::before {\n    content: '#';\n}\nmain.view.product-issue-comment div.header span.number::after {\n    content: ' - ';\n}\n\nmain.view.product-issue-comment div.header span.value.product_user_name {\n    font-weight: bold;\n}\n\nmain.view.product-issue-comment div.header span.date {\n    font-weight: bold;\n}\n\nmain.view.product-issue-comment div.header span.assignees > * {\n    margin-left: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
